@font-face {
  font-family: Glober;
  font-weight: normal;
  font-style: normal;
  src: url("/assets/fonts/Glober-Regular/Glober-Regular.otf") format("opentype");
  src: url("/assets/fonts/Glober-Regular/Glober-Regular.eot");
  src: url("/assets/fonts/Glober-Regular/Glober-Regular.woff2") format("woff2"), url("/assets/fonts/Glober-Regular/Glober-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Glober-Regular/Glober-Regular.woff") format("woff"), url("/assets/fonts/Glober-Regular/Glober-Regular.ttf") format("truetype"), url("/assets/fonts/Glober-Regular/Glober-Regular.svg#Glober-Regular") format("svg"); }

@font-face {
  font-family: Glober;
  font-weight: normal;
  font-style: italic;
  src: url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.otf") format("opentype");
  src: url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.eot");
  src: url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.woff2") format("woff2"), url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.woff") format("woff"), url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.ttf") format("truetype"), url("/assets/fonts/Glober-Regular-Italic/Glober-RegularItalic.svg#Glober-RegularItalic") format("svg"); }

@font-face {
  font-family: Glober;
  font-weight: bold;
  font-style: normal;
  src: url("/assets/fonts/Glober-Bold/Glober-Bold.otf") format("opentype");
  src: url("/assets/fonts/Glober-Bold/Glober-Bold.eot");
  src: url("/assets/fonts/Glober-Bold/Glober-Bold.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Glober-Bold/Glober-Bold.woff2") format("woff2"), url("/assets/fonts/Glober-Bold/Glober-Bold.woff") format("woff"), url("/assets/fonts/Glober-Bold/Glober-Bold.ttf") format("truetype"), url("/assets/fonts/Glober-Bold/Glober-Bold.svg#Glober-Bold") format("svg"); }

@font-face {
  font-family: Glober;
  font-weight: bold;
  font-style: italic;
  src: url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.otf") format("opentype");
  src: url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.eot");
  src: url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.woff2") format("woff2"), url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.woff") format("woff"), url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.ttf") format("truetype"), url("/assets/fonts/Glober-Bold-Italic/Glober-BoldItalic.svg#Glober-BoldItalic") format("svg"); }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #00a14e;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #e7451c;
  --secondary: #6c757d;
  --success: #00a14e;
  --info: #3290ce;
  --warning: #ffc107;
  --danger: #e7451c;
  --light: #f8f9fa;
  --dark: #3c3c3b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1250px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Glober;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3c3c3b;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3c3c3b;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #151515;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 3rem; }

h2, .h2 {
  font-size: 2.8125rem; }

h3, .h3 {
  font-size: 2.625rem; }

h4, .h4 {
  font-size: 1.875rem; }

h5, .h5 {
  font-size: 1.625rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 480px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1250px) {
    .container {
      max-width: 1190px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1250px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3c3c3b; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #3c3c3b;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f8cbbf; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f39e89; }

.table-hover .table-primary:hover {
  background-color: #f5b8a8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f5b8a8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e5cd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7acea3; }

.table-hover .table-success:hover {
  background-color: #a5dec0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a5dec0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c6e0f1; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #94c5e6; }

.table-hover .table-info:hover {
  background-color: #b2d5ec; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b2d5ec; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8cbbf; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f39e89; }

.table-hover .table-danger:hover {
  background-color: #f5b8a8; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5b8a8; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8c8c8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9a9a99; }

.table-hover .table-dark:hover {
  background-color: #bbbbbb; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bbbbbb; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 479.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1249.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #f3a38f;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #3c3c3b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00a14e; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(0, 161, 78, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00a14e;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300a14e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00a14e;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00a14e;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300a14e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00a14e;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00a14e; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00a14e; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00a14e; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00d467;
  background-color: #00d467; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00a14e; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00a14e; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00a14e;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e7451c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(231, 69, 28, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e7451c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e7451c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e7451c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e7451c;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e7451c;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e7451c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e7451c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e7451c;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e7451c; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e7451c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #e7451c; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ec6b4a;
  background-color: #ec6b4a; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e7451c; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e7451c; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e7451c;
  box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 480px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #3c3c3b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #3c3c3b;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #e7451c;
  border-color: #e7451c; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #c83915;
    border-color: #bc3614; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 97, 62, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #bc3614;
    border-color: #b13313; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 97, 62, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #00a14e;
  border-color: #00a14e; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #007b3b;
    border-color: #006e35; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 175, 105, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #00a14e;
    border-color: #00a14e; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #006e35;
    border-color: #00612f; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 175, 105, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #3290ce;
  border-color: #3290ce; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #2a7bb0;
    border-color: #2873a5; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 161, 213, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #3290ce;
    border-color: #3290ce; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #2873a5;
    border-color: #256c9b; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(81, 161, 213, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #e7451c;
  border-color: #e7451c; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c83915;
    border-color: #bc3614; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 97, 62, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #bc3614;
    border-color: #b13313; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 97, 62, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #3c3c3b;
  border-color: #3c3c3b; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #292928;
    border-color: #222222; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 89, 88, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #3c3c3b;
    border-color: #3c3c3b; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #222222;
    border-color: #1c1c1b; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 89, 88, 0.5); }

.btn-outline-primary {
  color: #e7451c;
  border-color: #e7451c; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #e7451c;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #00a14e;
  border-color: #00a14e; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #00a14e;
    border-color: #00a14e; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00a14e;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #00a14e;
    border-color: #00a14e; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.5); }

.btn-outline-info {
  color: #3290ce;
  border-color: #3290ce; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #3290ce;
    border-color: #3290ce; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 144, 206, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3290ce;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3290ce;
    border-color: #3290ce; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 144, 206, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #e7451c;
  border-color: #e7451c; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e7451c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #3c3c3b;
  border-color: #3c3c3b; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #3c3c3b;
    border-color: #3c3c3b; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 60, 59, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3c3c3b;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3c3c3b;
    border-color: #3c3c3b; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 60, 59, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3c3c3b;
  text-decoration: none; }
  .btn-link:hover {
    color: #151515;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #3c3c3b;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 480px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1250px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #e7451c; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #e7451c;
    background-color: #e7451c; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #f3a38f; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #f8c9bd;
    border-color: #f8c9bd; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #e7451c;
  background-color: #e7451c; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(231, 69, 28, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(231, 69, 28, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(231, 69, 28, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(231, 69, 28, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #f3a38f;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #f3a38f;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #e7451c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f8c9bd; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #e7451c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f8c9bd; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #e7451c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f8c9bd; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #e7451c; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 479.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1249.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1250px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 480px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 480px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 480px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3c3c3b;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #151515;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #e7451c;
  border-color: #e7451c; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #e7451c; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #bc3614; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #ffffff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #00a14e; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #006e35; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 78, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #3290ce; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #2873a5; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 144, 206, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #e7451c; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #bc3614; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(231, 69, 28, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #3c3c3b; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #222222; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(60, 60, 59, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 480px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #78240f;
  background-color: #fadad2;
  border-color: #f8cbbf; }
  .alert-primary hr {
    border-top-color: #f5b8a8; }
  .alert-primary .alert-link {
    color: #4b1609; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #005429;
  background-color: #ccecdc;
  border-color: #b8e5cd; }
  .alert-success hr {
    border-top-color: #a5dec0; }
  .alert-success .alert-link {
    color: #002110; }

.alert-info {
  color: #1a4b6b;
  background-color: #d6e9f5;
  border-color: #c6e0f1; }
  .alert-info hr {
    border-top-color: #b2d5ec; }
  .alert-info .alert-link {
    color: #102e42; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #78240f;
  background-color: #fadad2;
  border-color: #f8cbbf; }
  .alert-danger hr {
    border-top-color: #f5b8a8; }
  .alert-danger .alert-link {
    color: #4b1609; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1f1f1f;
  background-color: #d8d8d8;
  border-color: #c8c8c8; }
  .alert-dark hr {
    border-top-color: #bbbbbb; }
  .alert-dark .alert-link {
    color: #060606; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #e7451c;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #3c3c3b;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #e7451c;
    border-color: #e7451c; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 480px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1250px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #78240f;
  background-color: #f8cbbf; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #78240f;
    background-color: #f5b8a8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #78240f;
    border-color: #78240f; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #005429;
  background-color: #b8e5cd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #005429;
    background-color: #a5dec0; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #005429;
    border-color: #005429; }

.list-group-item-info {
  color: #1a4b6b;
  background-color: #c6e0f1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1a4b6b;
    background-color: #b2d5ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #1a4b6b;
    border-color: #1a4b6b; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #78240f;
  background-color: #f8cbbf; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #78240f;
    background-color: #f5b8a8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #78240f;
    border-color: #78240f; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1f1f1f;
  background-color: #c8c8c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1f1f1f;
    background-color: #bbbbbb; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f1f1f;
    border-color: #1f1f1f; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 480px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1250px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Glober;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Glober;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #3c3c3b; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #e7451c !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #bc3614 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #00a14e !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #006e35 !important; }

.bg-info {
  background-color: #3290ce !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2873a5 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #e7451c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bc3614 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3c3c3b !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #222222 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #e7451c !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #00a14e !important; }

.border-info {
  border-color: #3290ce !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #e7451c !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3c3c3b !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1250px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1250px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1250px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 4rem !important; }

.mt-6,
.my-6 {
  margin-top: 4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 4rem !important; }

.m-7 {
  margin: 5rem !important; }

.mt-7,
.my-7 {
  margin-top: 5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 5rem !important; }

.m-8 {
  margin: 6.25rem !important; }

.mt-8,
.my-8 {
  margin-top: 6.25rem !important; }

.mr-8,
.mx-8 {
  margin-right: 6.25rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 6.25rem !important; }

.ml-8,
.mx-8 {
  margin-left: 6.25rem !important; }

.m-9 {
  margin: 7.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 7.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 7.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 7.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 7.5rem !important; }

.m-10 {
  margin: 9.375rem !important; }

.mt-10,
.my-10 {
  margin-top: 9.375rem !important; }

.mr-10,
.mx-10 {
  margin-right: 9.375rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 9.375rem !important; }

.ml-10,
.mx-10 {
  margin-left: 9.375rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 4rem !important; }

.pt-6,
.py-6 {
  padding-top: 4rem !important; }

.pr-6,
.px-6 {
  padding-right: 4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 4rem !important; }

.pl-6,
.px-6 {
  padding-left: 4rem !important; }

.p-7 {
  padding: 5rem !important; }

.pt-7,
.py-7 {
  padding-top: 5rem !important; }

.pr-7,
.px-7 {
  padding-right: 5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 5rem !important; }

.pl-7,
.px-7 {
  padding-left: 5rem !important; }

.p-8 {
  padding: 6.25rem !important; }

.pt-8,
.py-8 {
  padding-top: 6.25rem !important; }

.pr-8,
.px-8 {
  padding-right: 6.25rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 6.25rem !important; }

.pl-8,
.px-8 {
  padding-left: 6.25rem !important; }

.p-9 {
  padding: 7.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 7.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 7.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 7.5rem !important; }

.pl-9,
.px-9 {
  padding-left: 7.5rem !important; }

.p-10 {
  padding: 9.375rem !important; }

.pt-10,
.py-10 {
  padding-top: 9.375rem !important; }

.pr-10,
.px-10 {
  padding-right: 9.375rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 9.375rem !important; }

.pl-10,
.px-10 {
  padding-left: 9.375rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -4rem !important; }

.m-n7 {
  margin: -5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -5rem !important; }

.m-n8 {
  margin: -6.25rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -6.25rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -6.25rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -6.25rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -6.25rem !important; }

.m-n9 {
  margin: -7.5rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -7.5rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -7.5rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -7.5rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -7.5rem !important; }

.m-n10 {
  margin: -9.375rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -9.375rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -9.375rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -9.375rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -9.375rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4rem !important; }
  .m-sm-7 {
    margin: 5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 5rem !important; }
  .m-sm-8 {
    margin: 6.25rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 6.25rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 6.25rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 6.25rem !important; }
  .m-sm-9 {
    margin: 7.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 7.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 7.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 7.5rem !important; }
  .m-sm-10 {
    margin: 9.375rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 9.375rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 9.375rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 9.375rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 9.375rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4rem !important; }
  .p-sm-7 {
    padding: 5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 5rem !important; }
  .p-sm-8 {
    padding: 6.25rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 6.25rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 6.25rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 6.25rem !important; }
  .p-sm-9 {
    padding: 7.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 7.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 7.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 7.5rem !important; }
  .p-sm-10 {
    padding: 9.375rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 9.375rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 9.375rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 9.375rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 9.375rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -4rem !important; }
  .m-sm-n7 {
    margin: -5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -5rem !important; }
  .m-sm-n8 {
    margin: -6.25rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -6.25rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -6.25rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -6.25rem !important; }
  .m-sm-n9 {
    margin: -7.5rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -7.5rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -7.5rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -7.5rem !important; }
  .m-sm-n10 {
    margin: -9.375rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -9.375rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -9.375rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -9.375rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -9.375rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4rem !important; }
  .m-md-7 {
    margin: 5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 5rem !important; }
  .m-md-8 {
    margin: 6.25rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 6.25rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 6.25rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 6.25rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 6.25rem !important; }
  .m-md-9 {
    margin: 7.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 7.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 7.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 7.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 7.5rem !important; }
  .m-md-10 {
    margin: 9.375rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 9.375rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 9.375rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 9.375rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 9.375rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4rem !important; }
  .p-md-7 {
    padding: 5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 5rem !important; }
  .p-md-8 {
    padding: 6.25rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 6.25rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 6.25rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 6.25rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 6.25rem !important; }
  .p-md-9 {
    padding: 7.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 7.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 7.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 7.5rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 7.5rem !important; }
  .p-md-10 {
    padding: 9.375rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 9.375rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 9.375rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 9.375rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 9.375rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -4rem !important; }
  .m-md-n7 {
    margin: -5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -5rem !important; }
  .m-md-n8 {
    margin: -6.25rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -6.25rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -6.25rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -6.25rem !important; }
  .m-md-n9 {
    margin: -7.5rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -7.5rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -7.5rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -7.5rem !important; }
  .m-md-n10 {
    margin: -9.375rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -9.375rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -9.375rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -9.375rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -9.375rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4rem !important; }
  .m-lg-7 {
    margin: 5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 5rem !important; }
  .m-lg-8 {
    margin: 6.25rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 6.25rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 6.25rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 6.25rem !important; }
  .m-lg-9 {
    margin: 7.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 7.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 7.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 7.5rem !important; }
  .m-lg-10 {
    margin: 9.375rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 9.375rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 9.375rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 9.375rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 9.375rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4rem !important; }
  .p-lg-7 {
    padding: 5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 5rem !important; }
  .p-lg-8 {
    padding: 6.25rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 6.25rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 6.25rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 6.25rem !important; }
  .p-lg-9 {
    padding: 7.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 7.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 7.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 7.5rem !important; }
  .p-lg-10 {
    padding: 9.375rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 9.375rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 9.375rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 9.375rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 9.375rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -4rem !important; }
  .m-lg-n7 {
    margin: -5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -5rem !important; }
  .m-lg-n8 {
    margin: -6.25rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -6.25rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -6.25rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -6.25rem !important; }
  .m-lg-n9 {
    margin: -7.5rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -7.5rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -7.5rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -7.5rem !important; }
  .m-lg-n10 {
    margin: -9.375rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -9.375rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -9.375rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -9.375rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -9.375rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1250px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4rem !important; }
  .m-xl-7 {
    margin: 5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 5rem !important; }
  .m-xl-8 {
    margin: 6.25rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 6.25rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 6.25rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 6.25rem !important; }
  .m-xl-9 {
    margin: 7.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 7.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 7.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 7.5rem !important; }
  .m-xl-10 {
    margin: 9.375rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 9.375rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 9.375rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 9.375rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 9.375rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4rem !important; }
  .p-xl-7 {
    padding: 5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 5rem !important; }
  .p-xl-8 {
    padding: 6.25rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 6.25rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 6.25rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 6.25rem !important; }
  .p-xl-9 {
    padding: 7.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 7.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 7.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 7.5rem !important; }
  .p-xl-10 {
    padding: 9.375rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 9.375rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 9.375rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 9.375rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 9.375rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -4rem !important; }
  .m-xl-n7 {
    margin: -5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -5rem !important; }
  .m-xl-n8 {
    margin: -6.25rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -6.25rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -6.25rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -6.25rem !important; }
  .m-xl-n9 {
    margin: -7.5rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -7.5rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -7.5rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -7.5rem !important; }
  .m-xl-n10 {
    margin: -9.375rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -9.375rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -9.375rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -9.375rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -9.375rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1250px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #e7451c !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #a52f11 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #00a14e !important; }

a.text-success:hover, a.text-success:focus {
  color: #005529 !important; }

.text-info {
  color: #3290ce !important; }

a.text-info:hover, a.text-info:focus {
  color: #236591 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #e7451c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a52f11 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #3c3c3b !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #151515 !important; }

.text-body {
  color: #3c3c3b !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/**
  Inspired by https://gist.github.com/bjmiller121/902745cbb38d88178882
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#__next {
  display: flex;
  flex-direction: column;
  overflow-y: initial;
  overflow-x: hidden; }

body {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background: #f7fdff;
  background-image: -webkit-radial-gradient(31% 2%, #f7fdff, #e6f6fa);
  background-image: radial-gradient(circle at 31% 2%, #f7fdff, #e6f6fa); }

.truncate-string {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.z-index-1 {
  z-index: 1; }

.normal-line-height {
  line-height: 1; }

.normal-line-height-important {
  line-height: 1 !important; }

.line-height-1 {
  line-height: 1; }

.center-block {
  margin: 0 auto; }

.btn-outline-dark {
  width: 100%;
  height: 30px;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 3px;
  font-weight: bold; }

.white-btn {
  padding: 0.45rem 3rem;
  background-color: white;
  border-radius: 20px;
  border-color: transparent;
  color: #3c3c3b;
  font-size: 1rem;
  font-weight: bold; }

.white-to-grey-gradient {
  background-image: linear-gradient(to bottom, #f7f7f7, #ededed); }

.grey-label-bg {
  background-color: #f9f9f9;
  border-radius: 5px; }

.cursor-pointer {
  cursor: pointer; }

@media (max-width: 767.98px) {
  .bb-1-grey {
    border-bottom: 1px solid rgba(60, 60, 59, 0.1); } }

.flex-grow-2 {
  flex-grow: 2; }

.flex-grow-3 {
  flex-grow: 3; }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: 0; }

.is-current a,
.is-current {
  color: red; }

.clear-ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.small-logo {
  max-width: 154px;
  width: 100%; }

.max-width-420 {
  max-width: 420px; }

.custom-having-questions {
  position: relative;
  z-index: 2;
  margin-top: -150px; }
  @media (min-width: 480px) {
    .custom-having-questions {
      margin-top: -90px; } }
  @media (min-width: 768px) {
    .custom-having-questions {
      margin-top: -135px; } }
  @media (min-width: 1024px) {
    .custom-having-questions {
      margin-top: -185px; } }

blockquote {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.56; }

.form-control:focus {
  box-shadow: none !important;
  border: none;
  background: transparent !important; }

.nav-tabs .nav-link {
  line-height: 1;
  font-size: 0.875rem;
  padding: 12px 17px 11px 17px; }
  @media (min-width: 768px) {
    .nav-tabs .nav-link {
      padding: 18px 29px 15px 29px;
      font-size: 1rem; } }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active,
.nav-tabs {
  border-radius: 0;
  border-color: rgba(60, 60, 50, 0.3); }

.nav-tabs .nav-link.active {
  font-weight: bold;
  border-bottom: transparent; }

.line-through {
  text-decoration: line-through; }

.no-link {
  text-decoration: none !important; }
  .no-link:hover {
    text-decoration: none; }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left; } }

@media (min-width: 480px) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 3rem; }
  h3, .h3 {
    font-size: 3rem; }
  h4, .h4 {
    font-size: 3rem; } }

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 3.125rem; }
  .bg-md-transparent {
    background-color: transparent !important; }
  .text-md-center {
    text-align: center; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left; }
  .overflow-lg-hidden {
    overflow: hidden; }
  h1, .h1 {
    font-size: 3.125rem; }
  .flex-basis-25 {
    flex-basis: 25% !important; }
  .flex-basis-80 {
    flex-basis: 80% !important; }
  .mw-70 {
    max-width: 70%; }
  .flex-basis-25 {
    flex-basis: 25% !important; }
  .flex-basis-80 {
    flex-basis: 80% !important; }
  .mw-70 {
    max-width: 70%; }
  .text-sm-right {
    text-align: right !important; }
  .text-md-right {
    text-align: right !important; }
  .text-lg-right {
    text-align: right !important; }
  .bg-lg-transparent {
    background-color: transparent !important; } }

@media (min-width: 1250px) {
  .text-xl-right {
    text-align: right !important; }
  h1, .h1 {
    font-size: 4rem; }
  .w-xl-100 {
    width: 100%; } }

/* Components */
.generic-table-container {
  background: #ffffff;
  width: 100%;
  padding: 54px 0 0;
  max-width: 100%; }
  .generic-table-container .description {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.33; }
  .generic-table-container .comparison-icon {
    height: 50px;
    max-width: 90%; }
  .generic-table-container .sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: white;
    padding-left: 230px;
    display: flex;
    width: 100%;
    max-width: 540px;
    margin-left: calc((100% - 540px) / 2);
    transition: all 500ms ease 0s; }
    @media (prefers-reduced-motion: reduce) {
      .generic-table-container .sticky-header {
        transition: none; } }
    .generic-table-container .sticky-header .scroll-container {
      overflow-x: auto;
      width: 100%;
      display: flex;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none; }
      .generic-table-container .sticky-header .scroll-container::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important; }
    .generic-table-container .sticky-header .column {
      min-width: 100px;
      width: 100px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px; }
      .generic-table-container .sticky-header .column.sticky {
        position: absolute;
        left: 130px; }
        .generic-table-container .sticky-header .column.sticky:after {
          content: '';
          position: absolute;
          right: -20px;
          bottom: 0;
          top: 0;
          width: 20px;
          background: rgba(60, 60, 50, 0.2);
          background: -webkit-linear-gradient(left, rgba(60, 60, 50, 0.2), transparent);
          /* For Safari 5.1 to 6.0 */
          background: -o-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
          /* For Opera 11.1 to 12.0 */
          background: -moz-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
          /* For Firefox 3.6 to 15 */
          background: linear-gradient(to right, rgba(60, 60, 50, 0.2), transparent);
          /* Standard syntax */ }
  .generic-table-container .table-wrap {
    position: relative;
    height: calc(100vh - 80px - 70px);
    max-height: calc(100vh - 80px - 70px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    margin: 0 -15px;
    padding: 0 15px; }
    .generic-table-container .table-wrap .generic-table {
      margin-left: 230px;
      width: 100%;
      empty-cells: show;
      margin-top: 24px;
      table-layout: fixed; }
      .generic-table-container .table-wrap .generic-table.sticky-table {
        position: fixed;
        top: 70px;
        left: 0;
        width: 100%;
        z-index: 99;
        transition: all 500ms ease 0s; }
        @media (prefers-reduced-motion: reduce) {
          .generic-table-container .table-wrap .generic-table.sticky-table {
            transition: none; } }
      .generic-table-container .table-wrap .generic-table td {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        width: 100px;
        height: 70px; }
        .generic-table-container .table-wrap .generic-table td.sticky-column {
          position: absolute;
          top: auto;
          left: 130px;
          background: white;
          display: flex;
          justify-content: center;
          align-items: center; }
          .generic-table-container .table-wrap .generic-table td.sticky-column::after {
            content: '';
            position: absolute;
            right: -20px;
            bottom: 0;
            top: 0;
            width: 20px;
            background: rgba(60, 60, 50, 0.2);
            background: -webkit-linear-gradient(left, rgba(60, 60, 50, 0.2), transparent);
            /* For Safari 5.1 to 6.0 */
            background: -o-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
            /* For Opera 11.1 to 12.0 */
            background: -moz-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
            /* For Firefox 3.6 to 15 */
            background: linear-gradient(to right, rgba(60, 60, 50, 0.2), transparent);
            /* Standard syntax */ }
      .generic-table-container .table-wrap .generic-table th {
        text-align: left;
        font-size: 0.75rem;
        font-weight: normal;
        padding: 0 8px;
        background: white;
        width: 100px;
        height: 70px; }
        .generic-table-container .table-wrap .generic-table th.sticky-column {
          position: absolute;
          top: auto;
          left: 0;
          width: 130px;
          display: flex;
          align-items: center; }
          .generic-table-container .table-wrap .generic-table th.sticky-column.second {
            left: 130px;
            width: 100px;
            display: flex;
            justify-content: center; }
            .generic-table-container .table-wrap .generic-table th.sticky-column.second:after {
              content: '';
              position: absolute;
              right: -20px;
              bottom: 0;
              top: 0;
              width: 20px;
              background: rgba(60, 60, 50, 0.2);
              background: -webkit-linear-gradient(left, rgba(60, 60, 50, 0.2), transparent);
              /* For Safari 5.1 to 6.0 */
              background: -o-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
              /* For Opera 11.1 to 12.0 */
              background: -moz-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
              /* For Firefox 3.6 to 15 */
              background: linear-gradient(to right, rgba(60, 60, 50, 0.2), transparent);
              /* Standard syntax */
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              top: -16px; }
      .generic-table-container .table-wrap .generic-table .spacer td {
        height: 16px;
        padding: 0;
        background: white; }
      .generic-table-container .table-wrap .generic-table .section {
        background: #f9f9f9;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        height: 60px; }
        .generic-table-container .table-wrap .generic-table .section th {
          font-weight: bold;
          font-size: 0.875rem;
          letter-spacing: 5.8px;
          text-transform: uppercase;
          padding: 18px 8px;
          background: transparent;
          height: 60px; }
          .generic-table-container .table-wrap .generic-table .section th.sticky-column {
            background: #f9f9f9;
            width: 230px; }
            .generic-table-container .table-wrap .generic-table .section th.sticky-column::after {
              content: '';
              position: absolute;
              right: -20px;
              bottom: 0;
              top: 0;
              width: 20px;
              background: rgba(60, 60, 50, 0.2);
              background: -webkit-linear-gradient(left, rgba(60, 60, 50, 0.2), transparent);
              /* For Safari 5.1 to 6.0 */
              background: -o-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
              /* For Opera 11.1 to 12.0 */
              background: -moz-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
              /* For Firefox 3.6 to 15 */
              background: linear-gradient(to right, rgba(60, 60, 50, 0.2), transparent);
              /* Standard syntax */
              top: -16px; }
      .generic-table-container .table-wrap .generic-table .blue-background {
        background: #f6fcfe !important; }
  .generic-table-container .table-scroll {
    overflow-x: auto; }
  .generic-table-container .navigation-icons-container {
    width: 100vw;
    padding-left: 130px;
    margin: 0 -15px;
    z-index: 0; }
    .generic-table-container .navigation-icons-container .navigation-button {
      width: 70px;
      height: 80px;
      background: white;
      position: relative;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      z-index: 0;
      outline: none; }
      .generic-table-container .navigation-icons-container .navigation-button:first-of-type {
        width: 100px;
        z-index: 1; }
        .generic-table-container .navigation-icons-container .navigation-button:first-of-type::after {
          content: '';
          position: absolute;
          right: -20px;
          bottom: 0;
          top: 0;
          width: 20px;
          background: rgba(60, 60, 50, 0.2);
          background: -webkit-linear-gradient(left, rgba(60, 60, 50, 0.2), transparent);
          /* For Safari 5.1 to 6.0 */
          background: -o-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
          /* For Opera 11.1 to 12.0 */
          background: -moz-linear-gradient(right, rgba(60, 60, 50, 0.2), transparent);
          /* For Firefox 3.6 to 15 */
          background: linear-gradient(to right, rgba(60, 60, 50, 0.2), transparent);
          /* Standard syntax */ }
      .generic-table-container .navigation-icons-container .navigation-button:hover img {
        opacity: 0.6; }

@media (min-width: 768px) {
  .generic-table-container {
    padding: 54px 0; }
    .generic-table-container .description {
      font-size: 1.375rem;
      line-height: 1.45; }
    .generic-table-container .comparison-icon {
      height: 50px;
      max-width: 90px; }
    .generic-table-container .table-wrap {
      height: auto;
      max-height: initial; }
      .generic-table-container .table-wrap .generic-table {
        margin-left: 0;
        margin-top: 64px; }
        .generic-table-container .table-wrap .generic-table.sticky-table {
          width: 720px;
          margin: 0 calc((100% - 720px) / 2); }
        .generic-table-container .table-wrap .generic-table td {
          width: auto;
          height: auto;
          padding: 18px 8px; }
          .generic-table-container .table-wrap .generic-table td.sticky-column {
            position: inherit;
            display: table-cell; }
            .generic-table-container .table-wrap .generic-table td.sticky-column::after {
              display: none; }
        .generic-table-container .table-wrap .generic-table th {
          width: auto;
          height: auto; }
          .generic-table-container .table-wrap .generic-table th.sticky-column {
            position: inherit;
            display: table-cell;
            width: 200px; }
            .generic-table-container .table-wrap .generic-table th.sticky-column.second {
              left: auto;
              width: auto;
              display: table-cell; }
              .generic-table-container .table-wrap .generic-table th.sticky-column.second:after {
                display: none; }
        .generic-table-container .table-wrap .generic-table .spacer td {
          height: 62px; }
        .generic-table-container .table-wrap .generic-table .section.sticky {
          position: fixed;
          top: 0;
          width: 100%; } }
        @media (min-width: 768px) and (min-width: 480px) {
          .generic-table-container .table-wrap .generic-table .section.sticky {
            max-width: 540px; } }
        @media (min-width: 768px) and (min-width: 768px) {
          .generic-table-container .table-wrap .generic-table .section.sticky {
            max-width: 720px; } }
        @media (min-width: 768px) and (min-width: 1024px) {
          .generic-table-container .table-wrap .generic-table .section.sticky {
            max-width: 960px; } }
        @media (min-width: 768px) and (min-width: 1250px) {
          .generic-table-container .table-wrap .generic-table .section.sticky {
            max-width: 1190px; } }

@media (min-width: 768px) {
        .generic-table-container .table-wrap .generic-table .section th.sticky-column {
          width: auto; }
          .generic-table-container .table-wrap .generic-table .section th.sticky-column::after {
            display: none; }
    .generic-table-container .navigation-icons-container {
      display: none; } }

@media (min-width: 1024px) {
  .generic-table-container .table-wrap .generic-table.sticky-table {
    width: 960px;
    margin: 0 calc((100% - 960px) / 2); }
  .generic-table-container .table-wrap .generic-table td {
    font-size: 0.875rem; }
  .generic-table-container .table-wrap .generic-table th {
    font-size: 1rem; }
  .generic-table-container .table-wrap .generic-table .section th {
    font-size: 1.125rem;
    letter-spacing: 7.5px; } }

@media (min-width: 1250px) {
  .generic-table-container {
    padding: 54px 0; }
    .generic-table-container .description {
      font-size: 1.5rem;
      line-height: 1.33; }
    .generic-table-container .table-wrap .generic-table.sticky-table {
      width: 1190px;
      margin: 0 calc((100% - 1190px) / 2); }
    .generic-table-container .table-wrap .generic-table td {
      font-size: 1rem; } }

.skills-table {
  display: flex;
  flex-wrap: wrap; }

.sel-subtitle {
  font-size: 1.625rem !important; }
  @media (min-width: 768px) {
    .sel-subtitle {
      font-size: 2.5rem !important; } }

.skills-custom-title {
  max-width: 300px; }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    .skills-custom-title {
      max-width: 460px; } }
  @media (min-width: 1024px) and (max-width: 1249.98px) {
    .skills-custom-title {
      max-width: 475px; } }
  @media (min-width: 1250px) {
    .skills-custom-title {
      max-width: 601px; } }

@media (min-width: 480px) and (max-width: 767.98px) {
  .other-skills-title {
    max-width: 396px; } }

@media (min-width: 1024px) and (max-width: 1249.98px) {
  .other-skills-title {
    max-width: 601px; } }

@media (min-width: 1250px) {
  .other-skills-title {
    max-width: 623px; } }

.skills-flex-size1 {
  flex: 0 0 75%; }

.skills-flex-size2 {
  flex: 0 0 25%; }

.skills-table-item {
  flex: 0 0 50%;
  margin-bottom: 75px;
  cursor: pointer; }
  .skills-table-item a {
    text-decoration: none !important; }
  .skills-table-item span {
    max-width: 180px;
    min-width: 155px;
    font-size: 1rem; }
  .skills-table-item img {
    width: 90px;
    height: 90px;
    border-radius: 7.5px; }
  .skills-table-item img, .skills-table-item span {
    display: block;
    margin: 0 auto; }

.skills-inline-icon {
  display: flex;
  align-items: flex-end; }
  .skills-inline-icon img {
    width: 82px;
    height: 82px;
    border-radius: 6.8px; }
  .skills-inline-icon span {
    font-size: 1.375rem;
    font-weight: bold; }

@media (min-width: 480px) {
  .skills-table-item {
    flex: 0 0 33.33%; } }

@media (min-width: 768px) {
  .skills-table-item span {
    font-size: 1.125rem; }
  .skills-table-item img {
    width: 120px;
    height: 120px; }
  .skills-inline-icon span {
    font-size: 2rem; } }

@media (min-width: 1024px) {
  .skills-table {
    max-width: 767px; } }

.research-outcome-box {
  width: 240px;
  height: 150px;
  padding: 20px 10px 10px;
  border-radius: 10px;
  background-color: rgba(0, 161, 78, 0.1);
  margin-bottom: 30px; }
  @media (min-width: 480px) {
    .research-outcome-box {
      flex: 0 0 47%; } }
  @media (min-width: 1024px) {
    .research-outcome-box {
      flex: 0 0 31%;
      margin-bottom: 50px; } }

.research-outcomes-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 770px; }
  @media (min-width: 480px) {
    .research-outcomes-list-wrapper {
      justify-content: space-between; }
      .research-outcomes-list-wrapper .research-outcome-box:nth-child(n+5) {
        margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .research-outcomes-list-wrapper .research-outcome-box:nth-child(n+3) {
      margin-bottom: 0; } }

.research-outcome-icons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }

.research-outcome-percentage span {
  color: #00a14e;
  font-size: 2rem;
  font-weight: bold; }

.research-outcome-description-wrapper {
  text-align: center; }
  .research-outcome-description-wrapper span {
    font-size: 1.125rem; }

/* Header */
.header-bg {
  background-color: #ebf8fc !important; }

.mega-menu {
  background: #ebf8fc; }

.menu-min-height {
  min-height: 70px; }

#main-navbar-nav > ul {
  min-height: calc(100vh - 70px); }

#main-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 110; }

.sticky-container-spacer {
  height: 70px; }

.navbar-light .navbar-toggler-icon {
  background-image: url("/assets/svg/icon-menu.svg");
  width: 1.0625rem; }

.mobile-icon-size {
  width: 3.125rem;
  height: 3.125rem; }

.main-menu-btn:focus {
  outline: 0; }

.main-menu-btn:hover {
  background: #ffffff; }

.main-menu-btn.collapsed {
  background: #ffffff; }
  .main-menu-btn.collapsed .main-menu-btn-inner {
    background: #ffffff; }

.main-menu-btn .main-menu-btn-inner {
  height: 60px;
  margin-top: 10px;
  border-radius: 5px; }

.large-screens-menu-design .nav-link a,
.mobile-navigation .nav-link a {
  display: block;
  font-size: 1.5rem;
  user-select: none;
  transition: text-shadow .3s; }
  .large-screens-menu-design .nav-link a:hover,
  .mobile-navigation .nav-link a:hover {
    text-shadow: 0 0 0.65px #333, 0 0 0.65px #333; }

.mega-item.is-open,
.react-tabs__tab--selected .nav-link a {
  text-shadow: 0 0 0.65px #333, 0 0 0.65px #333; }

.mobile-navigation .dropdown-content.level-2 .nav-link .mega-item {
  padding-left: 30px; }

.mobile-navigation .dropdown-content.level-3 .nav-link .mega-item {
  padding-left: 45px; }

.mobile-navigation .dropdown-content.level-4 .nav-link .mega-item {
  padding-left: 60px; }

.mobile-navigation .dropdown-content.level-2 {
  background: rgba(235, 235, 235, 0.3); }

.mobile-navigation .dropdown-content.level-3 {
  background: #ebebeb; }

.mobile-navigation .dropdown-content.level-4 {
  background: rgba(60, 60, 59, 0.1); }

.mobile-navigation ul > li {
  border-bottom: 1px solid rgba(60, 60, 59, 0.1); }
  .mobile-navigation ul > li:last-child {
    border: 0; }

.mobile-navigation .mega-item.has-children:after {
  content: url("/assets/svg/icon-expand.svg");
  transition: all .5s; }

.mobile-navigation .mega-item.has-children.is-open:after {
  content: url("/assets/svg/icon-collapse.svg"); }

.mobile-navigation .nav-link {
  display: block;
  padding: 0;
  line-height: 90px; }

@media (min-width: 768px) {
  .mega-menu {
    background: transparent; }
  .menu-column {
    padding: 0 0.35rem !important; }
  .mobile-navigation ul > li {
    border-bottom: 0; }
  .mobile-navigation > ul > li {
    border-bottom: 1px solid rgba(60, 60, 59, 0.1); }
  .mega-menu-panels-container li:hover {
    text-decoration: underline; }
  .react-tabs__tab-panel {
    display: none; }
    .react-tabs__tab-panel.react-tabs__tab-panel--selected {
      display: block; }
  .menu-soft-grey-li-bg {
    background-color: rgba(216, 216, 216, 0.1); }
  .large-screens-menu-design {
    border-top: 1px solid rgba(60, 60, 59, 0.1); }
    .large-screens-menu-design .react-tabs__tab--selected {
      background: white; }
      .large-screens-menu-design .react-tabs__tab--selected .mega-item:after {
        content: url("/assets/svg/icon-long-arrow-rd.svg"); }
    .large-screens-menu-design .menu-column .child.level-3 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 20px; }
      .large-screens-menu-design .menu-column .child.level-3.simple-child-link {
        font-size: initial;
        font-weight: normal;
        margin-bottom: initial; }
  .nav-link {
    display: block;
    padding: 0;
    line-height: 70px; }
    .nav-link a {
      display: block;
      padding: 0 1rem; } }

@media (min-width: 1250px) {
  .default-custom-dropdown-list {
    left: 0;
    right: 0;
    margin: 0 auto; }
    .default-custom-dropdown-list li:hover a {
      text-decoration: underline; }
  #main-navbar-nav > div > .nav-link,
  #main-navbar-nav .menu-custom-dropdown > .nav-link,
  #main-navbar-nav > .breakpoint__xl-only > .nav-link,
  #main-navbar-nav > .nav-link {
    margin: 0 .5rem; }
    #main-navbar-nav > div > .nav-link.active, #main-navbar-nav > div > .nav-link:hover,
    #main-navbar-nav .menu-custom-dropdown > .nav-link.active,
    #main-navbar-nav .menu-custom-dropdown > .nav-link:hover,
    #main-navbar-nav > .breakpoint__xl-only > .nav-link.active,
    #main-navbar-nav > .breakpoint__xl-only > .nav-link:hover,
    #main-navbar-nav > .nav-link.active,
    #main-navbar-nav > .nav-link:hover {
      background: white; }
  > div > .nav-link.active,
  .menu-custom-dropdown > .nav-link.active {
    background: white; }
  li.child.level-4 {
    line-height: 2.06; }
  .active-menu-item {
    background: white; }
  .mega-menu-tabs-container .nav-link {
    margin-right: 0;
    line-height: 90px; }
    .mega-menu-tabs-container .nav-link > a {
      padding-right: 0; }
  .mega-menu-panels-container .dropdown-content .nav-link {
    margin: 0; }
    .mega-menu-panels-container .dropdown-content .nav-link > a {
      padding: 0; }
  .large-screens-menu-design {
    border: 0; }
  .mega-menu-wrapper {
    position: absolute;
    right: 0;
    max-width: 1190px;
    width: 1190px;
    z-index: 999;
    border-radius: 5px;
    backdrop-filter: blur(16px);
    box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.1);
    background-color: white; }
  .default-custom-dropdown-list {
    box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.1); } }

.menu-max-height {
  max-height: calc(100vh - 70px);
  height: 100%;
  overflow-y: auto; }

.no-navlink-padding .nav-link {
  padding-bottom: 0; }

.mobile-icon-size .nav-link.active {
  background: white; }

/* Layout */
.page-title-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf8fc;
  position: fixed;
  z-index: 99; }
  .page-title-container::after {
    content: '';
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px; }

.blog-content-title {
  font-size: 1.375rem !important; }

@media (max-width: 1023.98px) {
  .blog-container {
    max-width: none; }
  .page-title-container {
    max-width: none; } }

@media (min-width: 768px) {
  .page-title-container {
    border: none;
    background: transparent;
    position: relative; }
    .page-title-container::after {
      display: none; }
  .blog-content-title {
    font-size: 2.5rem !important; } }

@media (min-width: 1024px) {
  .page-title-container {
    padding: 74px 20px 60px; } }

@media (min-width: 1250px) {
  .page-title-container {
    padding: 95px 20px 40px; } }

@media (min-width: 768px) {
  .subcategory-page-title-wrapper {
    padding-left: 60px;
    padding-right: 60px; } }

@media (min-width: 1250px) {
  .subcategory-page-title-wrapper {
    padding-left: 0;
    padding-right: 0; } }

.category-filter li {
  cursor: pointer;
  margin-bottom: 15px; }

.category-filter li:nth-child(odd) {
  padding-right: 7px;
  padding-left: 0; }

.category-filter li:nth-child(even) {
  padding-left: 7px;
  padding-right: 0; }

@media (min-width: 480px) {
  .category-filter li:nth-child(odd),
  .category-filter li:nth-child(even) {
    padding-left: 0;
    padding-right: 15px; }
  .category-filter li:nth-child(3n) {
    padding: 0; } }

@media (min-width: 1024px) {
  .category-filter li:nth-child(3n) {
    padding-right: 15px; }
  .category-filter li:last-child {
    padding-right: 0; } }

.products-thumbnail-list .tab-list .tab {
  cursor: pointer; }

.products-thumbnail-list .tab-list .tab.is-active > div {
  box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.1);
  background-color: #e7451c; }
  .products-thumbnail-list .tab-list .tab.is-active > div span {
    color: white; }

.products-thumbnail-list .react-tabs__tab {
  cursor: pointer; }

.subcategory-page-title {
  text-align: center;
  font-size: 1.75rem !important; }
  @media (min-width: 768px) {
    .subcategory-page-title {
      font-size: 2.5rem !important;
      text-align: initial; } }

/* Programs Compared */
.comparison-header-container {
  padding: 20px 0 60px; }
  .comparison-header-container .title {
    font-size: 30px;
    text-align: center; }
  .comparison-header-container .description {
    font-size: 18px;
    line-height: 1.22;
    text-align: center;
    padding: 35px 14% 0; }

@media (min-width: 768px) {
  .comparison-header-container .title {
    font-size: 48px;
    text-align: left; }
  .comparison-header-container .description {
    line-height: 1.44;
    text-align: left;
    padding: 0; } }

@media (min-width: 1250px) {
  .comparison-header-container {
    padding: 95px 0 124px; }
    .comparison-header-container .title {
      font-size: 64px; }
    .comparison-header-container .description {
      margin-left: 50px; } }

.testimonial-container {
  padding: 30px 0 85px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background: #f7fdff;
  background-image: -webkit-radial-gradient(86% 62%, #f7fdff, #97ddff);
  background-image: radial-gradient(circle at 86% 62%, #f7fdff, #97ddff); }
  .testimonial-container .carousel-container {
    min-height: 400px; }
  .testimonial-container .testimonial-title {
    font-size: 22px !important;
    line-height: 1.18 !important; }
  .testimonial-container .testimonial-message {
    font-size: 24px !important;
    line-height: 1.31 !important; }
  .testimonial-container .caption {
    font-size: 16px !important;
    color: #3c3c3b;
    text-align: center;
    line-height: 1 !important; }

@media (min-width: 480px) {
  .testimonial-container .carousel-container {
    min-height: 350px; }
  .testimonial-container .testimonial-message {
    font-size: 28px !important; } }

@media (min-width: 1024px) {
  .testimonial-container .carousel-container {
    min-height: 270px; }
  .testimonial-container .testimonial-message {
    font-size: 32px !important; } }

@media (min-width: 1250px) {
  .testimonial-container {
    padding-bottom: 115px; }
    .testimonial-container .carousel-container {
      min-height: 280px; }
    .testimonial-container .testimonial-message {
      font-size: 36px !important; } }

/* Articles */
.research-article h2 {
  font-size: 3rem; }
  @media (min-width: 1024px) {
    .research-article h2 {
      font-size: 4rem; } }

@media (min-width: 768px) {
  .article-box {
    padding: 36px 27px 35px 25px; } }

@media (min-width: 1024px) {
  .article-box {
    padding: 56px 90px 58px 40px; } }

.article-box h3 {
  font-size: 1.375rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal; }
  .article-box h3.para-style {
    font-size: 1rem;
    font-weight: normal; }

.article-box h4 {
  font-size: 0.75rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 5px; }

.article-box a {
  display: inline-block;
  color: #3c3c3b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 1.5;
  text-decoration: underline;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

/* Footer */
.footer-logo-margin {
  margin-right: 84px; }
  @media (min-width: 768px) {
    .footer-logo-margin {
      margin-right: 58px;
      margin-bottom: 60px; } }

.bottom-hr-margins {
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .bottom-hr-margins {
      margin-top: 41px !important; } }

.inline-ul > * {
  margin-right: 30px; }

.inline-ul > *:last-child {
  margin-right: 0; }

@media (max-width: 767.98px) {
  .special-copyright {
    order: 2;
    flex-basis: 100%;
    text-align: center; }
    .special-copyright > span {
      width: 100%; } }

.footer {
  background-color: #f9f9f9; }

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.inline-elements li {
  display: inline-block;
  margin-right: 30px; }

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion__button {
  cursor: pointer;
  padding: 20px 0;
  width: 100%;
  border: none;
  position: relative;
  outline: 0; }

.accordion__button:after {
  display: inline-block;
  content: '';
  height: 13px;
  width: 13px;
  background-image: url("/assets/svg/icon-expand.svg");
  position: absolute;
  right: 0;
  top: 23px; }

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  background-image: url("/assets/svg/icon-collapse.svg");
  width: 13px;
  height: 2px; }

.accordion__item__panel {
  padding-bottom: 10px; }

.accordion__panel {
  padding: 10px 0;
  animation: fadein 0.5s ease-in; }

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */
@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Home page */
.header-container .mv-characters {
  min-height: 265px;
  position: relative; }
  .header-container .mv-characters .top-child-01,
  .header-container .mv-characters .top-child-03,
  .header-container .mv-characters .top-tennis-ball {
    position: absolute; }
  .header-container .mv-characters .top-child-01 {
    top: 6px;
    left: 20px; }
  .header-container .mv-characters .top-child-03 {
    top: 25px;
    right: 20px; }
  .header-container .mv-characters .top-tennis-ball {
    right: 42.5%;
    top: -2px; }

.header-container .sub-heading {
  margin: 30px 40px; }

.header-container .bottom-background {
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background: #90ffa8;
  background-image: -webkit-radial-gradient(50% 0, #90ffa8, #2bd97f);
  background-image: radial-gradient(circle at 50% 0, #90ffa8, #2bd97f);
  padding: 476px 0 calc(13% + 200px);
  margin: -238px 0 calc(-13% - 300px); }

@media (min-width: 480px) {
  .header-container h1 {
    margin-top: 145px;
    margin-left: -20px; }
  .header-container .sub-heading {
    margin: 65px 40px 40px 0;
    line-height: 1.63; }
  .header-container .character-composition .character {
    position: absolute; }
    .header-container .character-composition .character.top-child-01 {
      top: 17px;
      left: -46px;
      width: 163px; }
    .header-container .character-composition .character.top-child-03 {
      width: 148px;
      top: -72px;
      right: -18px; }
    .header-container .character-composition .character.top-tennis-ball {
      width: 23px;
      top: -124px;
      left: 71px; }
  .header-container button {
    margin-left: -40px; }
  .header-container .bottom-background {
    padding: 476px 0 calc(13% + 200px);
    margin: -210px 0 calc(-13% - 300px); } }

@media (min-width: 768px) {
  .header-container {
    margin-bottom: 225px; }
    .header-container h1 {
      margin-top: 0;
      margin-left: 0; }
    .header-container .sub-heading {
      margin: 85px 35px 40px; }
    .header-container .character-composition {
      min-height: 500px; }
      .header-container .character-composition .character {
        position: absolute; }
        .header-container .character-composition .character.top-child-01 {
          width: 107px;
          top: 57px;
          left: -23px; }
        .header-container .character-composition .character.top-child-02 {
          width: 189px;
          bottom: 28px;
          left: 20px;
          z-index: 1; }
        .header-container .character-composition .character.top-child-03 {
          width: 115px;
          top: 60px;
          right: 59px; }
        .header-container .character-composition .character.top-tennis-ball {
          top: 10px;
          left: 87px; }
        .header-container .character-composition .character.top-child-04 {
          width: 161px;
          top: 89px;
          right: -88px; }
    .header-container button {
      margin-left: 0; }
    .header-container .bottom-background {
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      padding: 200px 0 calc(13% + 300px);
      margin: -300px 0 calc(-13% - 300px); } }

@media (min-width: 1024px) {
  .header-container h1 {
    font-size: 2.8125rem; }
  .header-container .sub-heading {
    margin: 70px 5px 40px; }
  .header-container .character-composition {
    min-height: 500px; }
    .header-container .character-composition .character {
      position: absolute; }
      .header-container .character-composition .character.top-child-01 {
        top: 77px;
        left: 26px; }
      .header-container .character-composition .character.top-child-02 {
        width: 192px;
        bottom: 86px;
        left: 158px;
        z-index: 1; }
      .header-container .character-composition .character.top-child-03 {
        top: 80px;
        right: 170px; }
      .header-container .character-composition .character.top-tennis-ball {
        top: 30px;
        left: 223px; }
      .header-container .character-composition .character.top-child-04 {
        top: 89px;
        right: 22px; }
  .header-container .bottom-background {
    margin: -275px 0 calc(-13% - 300px); } }

@media (min-width: 1250px) {
  .header-container {
    height: 730px;
    margin-bottom: 100px; }
    .header-container h1 {
      font-size: 3.125rem;
      margin-top: 80px;
      margin-left: 25px; }
    .header-container .sub-heading {
      margin: 90px 30px 40px 50px; }
    .header-container button {
      margin-left: 20px; }
    .header-container .character-composition {
      min-height: 500px; }
      .header-container .character-composition .character {
        position: absolute; }
        .header-container .character-composition .character.top-child-01 {
          width: 179px;
          top: 72px;
          left: 16px; }
        .header-container .character-composition .character.top-child-02 {
          width: 279px;
          bottom: -36px;
          left: 183px;
          z-index: 1; }
        .header-container .character-composition .character.top-child-03 {
          width: 207px;
          top: 104px;
          right: 198px; }
        .header-container .character-composition .character.top-tennis-ball {
          top: 22px;
          left: 287px; }
        .header-container .character-composition .character.top-child-04 {
          width: 227px;
          top: 89px;
          right: 22px; }
    .header-container .bottom-background {
      border-top-left-radius: 200px;
      border-top-right-radius: 200px;
      margin: -237px 0 calc(-13% - 300px);
      padding: 240px 0 calc(13% + 300px); } }

.journey-container {
  position: relative;
  top: -90px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background: #f7fdff;
  background-image: -webkit-radial-gradient(44% 75%, #f7fdff, #97ddff);
  background-image: radial-gradient(circle at 44% 75%, #f7fdff, #97ddff);
  z-index: 1;
  margin-bottom: -30px; }
  .journey-container .begin-container {
    position: relative;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #e7451c;
    padding: 33px 0;
    z-index: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; }
  .journey-container .whats-pa {
    padding: 65px 0; }
    .journey-container .whats-pa p {
      margin: 21px 35px 35px; }
    .journey-container .whats-pa .characters {
      margin: 65px 0; }
    .journey-container .whats-pa .lady-white {
      margin-right: 10px;
      margin-top: 15px;
      z-index: 1; }
    .journey-container .whats-pa .lady-blue {
      z-index: 1; }
    .journey-container .whats-pa .man-white {
      margin-left: 26px;
      z-index: 1; }
    .journey-container .whats-pa .our-origins {
      padding-bottom: 145px; }
    .journey-container .whats-pa .quote-sign {
      width: 123px;
      height: 120px;
      background: url("/assets/svg/icon-quotes.svg") no-repeat center center; }
    .journey-container .whats-pa .quote-text {
      font-size: 2.25rem;
      font-weight: bold;
      line-height: 1.17;
      margin: -18px 0 36px; }
    .journey-container .whats-pa .pre-line {
      width: 16px;
      height: 1px;
      display: inline-block;
      margin: 5px 12px 5px 0;
      background-color: #3c3c3b; }
    .journey-container .whats-pa .quote-author {
      margin-bottom: 64px;
      display: block; }
  .journey-container h2 {
    font-size: 2.625rem; }
  .journey-container .begin-text {
    padding: 0 62px 54px; }
  @media (min-width: 480px) {
    .journey-container .begin-container {
      margin: 0; }
      .journey-container .begin-container h2 {
        padding: 0; }
      .journey-container .begin-container .begin-text {
        padding: 0 48px 15px; }
    .journey-container .whats-pa {
      padding: 85px 0; }
      .journey-container .whats-pa .lady-white {
        position: absolute;
        top: -490px;
        right: -340px;
        width: 108px; }
      .journey-container .whats-pa .man-white {
        width: 170px; }
      .journey-container .whats-pa p {
        margin: 21px 50px 35px;
        line-height: 1.63; }
        .journey-container .whats-pa p.p-origins {
          margin: 21px 30px 35px; }
      .journey-container .whats-pa .our-origins {
        margin-top: 175px; }
      .journey-container .whats-pa .quote-sign {
        position: relative;
        top: 37px;
        left: -123px;
        z-index: -1; }
      .journey-container .whats-pa .quote-text {
        width: 335px;
        margin: 0 auto; }
      .journey-container .whats-pa .quote-author {
        display: inline-block;
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .journey-container .begin-container {
      position: absolute;
      width: 407px;
      right: -30px;
      top: -60px;
      margin: 0;
      padding: 23px 0 48px; }
      .journey-container .begin-container h2 {
        margin: 20px 40px; }
      .journey-container .begin-container .begin-text {
        padding: 0 54px 15px; }
    .journey-container .whats-pa p {
      margin: 21px 15px 35px; }
    .journey-container .whats-pa .characters {
      margin: 0 0 0 26%; }
      .journey-container .whats-pa .characters img {
        position: absolute; }
    .journey-container .whats-pa .lady-white {
      top: 22px;
      left: -15px;
      width: 94px;
      z-index: 1; }
    .journey-container .whats-pa .lady-blue {
      width: 133px;
      top: -60px;
      left: 48px; }
    .journey-container .whats-pa .man-white {
      width: 153px;
      top: 133px;
      left: 82px; }
    .journey-container .whats-pa .our-origins {
      margin-top: 245px;
      margin-left: 25px; }
      .journey-container .whats-pa .our-origins p {
        margin: 21px 0 35px; }
    .journey-container .whats-pa .quote-sign {
      position: absolute;
      top: -31px;
      left: 7px; }
    .journey-container .whats-pa .quote-text {
      width: 512px; } }
  @media (min-width: 1024px) {
    .journey-container .begin-container {
      width: 445px;
      right: 20px;
      padding: 20px 0 48px; }
      .journey-container .begin-container .begin-text {
        padding: 0 62px 15px; }
    .journey-container .whats-pa p {
      margin: 21px 25px 35px; }
    .journey-container .whats-pa .characters {
      margin: 0;
      height: 865px; }
    .journey-container .whats-pa .lady-white {
      top: 303px;
      left: -91px;
      width: 105px;
      z-index: 1; }
    .journey-container .whats-pa .lady-blue {
      width: 149px;
      top: 153px;
      left: 45px; }
    .journey-container .whats-pa .man-white {
      width: 170px;
      top: 386px;
      left: 150px; }
    .journey-container .whats-pa .our-origins {
      margin: 0;
      padding-bottom: 115px; }
      .journey-container .whats-pa .our-origins p {
        margin: 21px 15px 35px; }
    .journey-container .whats-pa .home-quotes {
      margin-top: 72px;
      display: flex;
      align-items: flex-end; }
    .journey-container .whats-pa .quote-sign {
      left: 125px; }
    .journey-container .whats-pa .pre-line {
      width: 19px; } }
  @media (min-width: 1250px) {
    .journey-container {
      margin-bottom: -90px; }
      .journey-container .begin-container {
        width: 475px;
        right: 10px;
        top: -40px; }
        .journey-container .begin-container .begin-text {
          padding: 0 88px 15px; }
      .journey-container .whats-pa {
        padding: 123px 0 50px; }
        .journey-container .whats-pa p {
          margin: 21px 65px 35px; }
        .journey-container .whats-pa .lady-white {
          top: 85px;
          left: -56px;
          width: 133px;
          z-index: 1; }
        .journey-container .whats-pa .lady-blue {
          width: 187px;
          top: -28px;
          left: 47px; }
        .journey-container .whats-pa .man-white {
          width: 214px;
          top: 197px;
          left: 153px; }
        .journey-container .whats-pa .our-origins p {
          margin: 21px 45px 35px; }
        .journey-container .whats-pa .quote-sign {
          left: 15px; }
        .journey-container .whats-pa .quote-text {
          margin: 0 50px 0 112px; } }

.values-container {
  background: #ffffff; }
  .values-container .actions {
    background: #ffffff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative; }
    .values-container .actions .more {
      height: 95px;
      color: #e7451c;
      font-weight: bold;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -55px;
      text-align: center; }
      .values-container .actions .more:after {
        content: '';
        width: 2px;
        height: 65px;
        background: #e7451c;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 25px; }
  .values-container .children-background {
    top: -280px;
    width: 100%;
    margin-bottom: -43%;
    position: relative; }
  @media (min-width: 768px) {
    .values-container {
      background: linear-gradient(to bottom, #f9f9f9, #cecece) #ffffff no-repeat 0 320px;
      background-size: 100% 181px; }
      .values-container #h-icon-01 {
        width: 48px; }
      .values-container #h-icon-02 {
        width: 45px; }
      .values-container #h-icon-03 {
        width: 51px; }
      .values-container #h-icon-04 {
        width: 41px; }
      .values-container #h-icon-05 {
        width: 46px; }
      .values-container button {
        width: auto; }
        .values-container button p {
          font-size: 0.875rem;
          width: 90px; }
      .values-container .children-background {
        top: 0;
        margin-bottom: 0; } }
  @media (min-width: 1024px) {
    .values-container {
      background-position: 0 410px;
      background-size: 100% 191px; }
      .values-container button p {
        width: 138px; }
      .values-container .children-background {
        width: 100%;
        margin-bottom: -4%; } }
  @media (min-width: 1250px) {
    .values-container {
      background: linear-gradient(to bottom, #f9f9f9, #cecece) no-repeat 0 367px; }
      .values-container .actions {
        height: 50px; }
      .values-container button {
        width: 186px; }
        .values-container button P {
          width: 151px;
          font-size: 1rem; }
      .values-container #h-icon-01,
      .values-container #h-icon-02,
      .values-container #h-icon-03,
      .values-container #h-icon-04,
      .values-container #h-icon-05 {
        width: auto; }
      .values-container .children-background {
        width: 1215px; } }

.pre-footer button {
  margin: 20px 0; }
  .pre-footer button[aria-expanded="true"] {
    background: #ffffff; }
    .pre-footer button[aria-expanded="true"] .pre-footer-arrow img {
      transform: rotate(180deg); }
  .pre-footer button .pre-footer-arrow img {
    margin-left: 14px; }

.pre-footer .btn-light:focus, .pre-footer .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 236, 236, 0.5); }

.pre-footer .btn-light:not(:disabled):not(.disabled):active,
.pre-footer .btn-light:not(:disabled):not(.disabled).active,
.pre-footer .show > .btn-light.dropdown-toggle {
  background-color: #ededed;
  border-color: #ececec; }

.pre-footer .pre-footer-title {
  font-weight: bold;
  margin-top: 10px; }
  .pre-footer .pre-footer-title.last {
    margin-bottom: 20px; }

.pre-footer .small-text {
  font-size: 0.75rem;
  margin-bottom: 26px;
  display: block; }

.pre-footer ul {
  list-style: none;
  padding: 0; }
  .pre-footer ul li {
    line-height: 1.88; }

.pre-footer .special-link {
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: #3c3c3b underline;
  text-underline-position: under;
  -ms-text-underline-position: below; }

.how-it-works-container h2 {
  line-height: 1; }

.how-it-works-container p {
  line-height: 1.75; }

.how-it-works-container a {
  padding: 0 12px;
  height: 40px;
  line-height: 40px; }

@media (min-width: 480px) {
  .how-it-works-container a {
    position: relative;
    top: -5px;
    height: 80px;
    line-height: 80px; } }

@media (min-width: 1250px) {
  .how-it-works-container {
    background: #ffffff;
    padding-top: 58px; } }

/* Login */
.login-form {
  font-size: 24px; }

.character-image.boy {
  width: 50%; }

.character-image.girl {
  width: 50%;
  margin-top: 40px; }

@media (min-width: 480px) {
  .character-image {
    position: absolute;
    z-index: 99; }
    .character-image.boy {
      width: 156px;
      left: -50%; }
    .character-image.girl {
      width: 169px;
      margin: 0;
      right: -50%; } }

@media (min-width: 768px) {
  .login-container {
    padding-bottom: 100px; }
  .character-image.boy {
    width: 144px;
    left: auto;
    right: -75px;
    margin-top: 110px; }
  .character-image.girl {
    width: 137px;
    right: auto;
    left: -60px;
    margin-top: 130px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); } }

@media (min-width: 1024px) {
  .character-image.boy {
    width: 164px;
    right: -115px;
    margin-top: 140px; }
  .character-image.girl {
    width: 157px;
    left: -80px; } }

@media (min-width: 1250px) {
  .login-container {
    padding-bottom: 160px; }
  .character-image.boy {
    width: 201px;
    right: -235px;
    margin-top: 10px; }
  .character-image.girl {
    width: 171px;
    margin-top: 0;
    left: -180px; } }

/* Forgot Password */
.max-350 {
  max-width: 350px; }

/* Curriculum page level 1*/
.font-size-18 {
  font-size: 1.125rem; }

.header-curriculum h1 {
  font-size: 2.8125rem; }

.category-curriculum .card {
  border: none; }

.category-curriculum .card-deck .hover-state.card:hover {
  box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.1); }

.category-curriculum .card-deck .card {
  min-width: 220px;
  border-radius: 5px;
  background-color: #ffffff; }
  .category-curriculum .card-deck .card img {
    width: 110px;
    height: 110px;
    object-fit: contain; }

@media (min-width: 1024px) {
  .category-curriculum .card-deck .card {
    min-width: 212px; } }

.category-curriculum .title {
  font-size: 16px;
  font-weight: bold; }

.category-curriculum .curriculum-icon {
  width: 190px; }
  .category-curriculum .curriculum-icon:hover {
    text-decoration: underline; }
  .category-curriculum .curriculum-icon img {
    width: 80px;
    height: 80px;
    object-fit: contain; }

/* Registration */
.registration-container {
  padding-top: 0;
  padding-bottom: 140px; }
  .registration-container .character-image {
    width: 100%; }
    .registration-container .character-image.girl {
      margin-top: 20px; }

@media (min-width: 480px) {
  .registration-container {
    padding-bottom: 200px; }
    .registration-container .character-image {
      position: absolute;
      z-index: 99;
      width: 160px; }
      .registration-container .character-image.boy {
        left: -25%; }
      .registration-container .character-image.girl {
        right: -25%; } }

@media (min-width: 768px) {
  .registration-container {
    padding-bottom: 200px; }
    .registration-container .character-image {
      width: 116px; }
      .registration-container .character-image.boy {
        right: 0;
        top: 200px;
        left: auto; }
      .registration-container .character-image.girl {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        top: 270px;
        left: -20px; } }

@media (min-width: 1024px) {
  .registration-container {
    padding-bottom: 150px; }
    .registration-container .character-image.boy {
      width: 150px;
      top: auto;
      bottom: -40px;
      right: -20px; }
    .registration-container .character-image.girl {
      width: 140px;
      top: auto;
      bottom: -40px;
      left: 0; } }

@media (min-width: 1250px) {
  .registration-container {
    padding-top: 102px;
    padding-bottom: 250px; }
    .registration-container .character-image.boy {
      width: 202px;
      right: -40px;
      bottom: -40px; }
    .registration-container .character-image.girl {
      width: 174px;
      bottom: 0; } }

/* Table of contents */
.table-of-contents-wrapper {
  background: #ebf8fc;
  box-shadow: 0 20px 22px 0 rgba(0, 0, 0, 0.1);
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10; }
  @media (min-width: 768px) {
    .table-of-contents-wrapper {
      box-shadow: none;
      background: transparent; } }
  .table-of-contents-wrapper .table-of-contents {
    height: 60px; }
    .table-of-contents-wrapper .table-of-contents a.active {
      font-weight: bold; }
      .table-of-contents-wrapper .table-of-contents a.active p {
        margin-left: 10px; }
    .table-of-contents-wrapper .table-of-contents a.active:before {
      content: url("/assets/svg/icon-arrow-rd.svg");
      width: 3px;
      height: 6px;
      position: absolute;
      left: 0; }
    .table-of-contents-wrapper .table-of-contents .table-header {
      cursor: pointer; }
      .table-of-contents-wrapper .table-of-contents .table-header p {
        text-overflow: ellipsis;
        overflow: hidden;
        word-wrap: break-word;
        max-height: 60px;
        padding: 5px 0; }
  .table-of-contents-wrapper .table-of-contents-list {
    width: 100%;
    height: calc(100vh - 130px);
    overflow: auto; }
    .table-of-contents-wrapper .table-of-contents-list ul li {
      padding: 15px 0;
      border-top: 2px solid #d8d8d8;
      cursor: pointer; }
      .table-of-contents-wrapper .table-of-contents-list ul li p {
        font-size: 1.375rem;
        margin: 0; }
      .table-of-contents-wrapper .table-of-contents-list ul li a.active p {
        font-size: 24px;
        font-weight: bold; }
  .table-of-contents-wrapper.is-open .table-of-contents-wrapper {
    background: #ffffff; }
  .table-of-contents-wrapper.is-open .table-of-contents {
    margin-bottom: 10px; }
    .table-of-contents-wrapper.is-open .table-of-contents p {
      font-size: 18px;
      line-height: 18px;
      font-style: italic; }
      .table-of-contents-wrapper.is-open .table-of-contents p:hover {
        font-weight: normal; }
  .table-of-contents-wrapper.is-open .table-of-contents-list .table-of-contents-header {
    font-weight: bold;
    background: #ebebeb;
    padding: 14px 15px;
    text-align: right;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 18px; }

.toc-fixed-menu {
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden; }
  .toc-fixed-menu p {
    font-size: 0.8125rem;
    line-height: 1.75;
    margin: 0; }
    .toc-fixed-menu p:hover {
      font-weight: bold; }
  .toc-fixed-menu a.active {
    font-weight: bold; }
    .toc-fixed-menu a.active p {
      margin-left: 10px; }
  .toc-fixed-menu a.active:before {
    content: url("/assets/svg/icon-arrow-rd.svg");
    width: 3px;
    height: 6px;
    position: absolute;
    left: 0;
    top: -4px; }
  .toc-fixed-menu ul {
    margin-top: 20px; }
    .toc-fixed-menu ul li {
      padding: 0;
      border-top: none;
      position: relative;
      margin-bottom: 10px; }
      .toc-fixed-menu ul li:last-child {
        margin-bottom: 0; }
      .toc-fixed-menu ul li p {
        font-size: 0.8125rem;
        line-height: 1.75;
        margin: 0;
        cursor: pointer; }
      .toc-fixed-menu ul li a.active p {
        font-size: 0.8125rem;
        font-weight: bold; }
  .toc-fixed-menu .toc-fixed-menu-header {
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 30px; }

.toc-no-margin .toc-fixed-menu ul {
  margin-top: 20px !important; }

/* Introduction page */
.toc-fixed-menu.introduction-toc ul {
  margin-top: 20px; }

.introduction-article-container {
  padding-bottom: 20px;
  max-width: 700px; }

@media (min-width: 1024px) {
  .introduction-article-container {
    padding-bottom: 55px;
    max-width: 635px; } }

@media (min-width: 1250px) {
  .introduction-article-container {
    padding-bottom: 79px; } }

/* Accolades */
.toc-fixed-menu.accolades-toc ul {
  margin-top: 20px; }

/* Cart */
.cart-wrapper {
  margin-bottom: 72px; }
  @media (min-width: 1250px) {
    .cart-wrapper {
      position: relative;
      min-height: 700px; }
      .cart-wrapper .cart-items {
        max-width: 1000px; }
      .cart-wrapper .cart-bottom {
        max-width: 392px;
        position: absolute;
        top: 0;
        right: -8px; } }
  .cart-wrapper hr {
    margin-top: 0.175rem;
    margin-bottom: 1.0625rem;
    border-top: 1px solid rgba(60, 60, 59, 0.3); }

.cart-items,
.cart-bottom-left,
.cart-bottom-right {
  padding: 20px;
  background: #ffffff; }

.cart-bottom-left,
.cart-bottom-right {
  box-shadow: 0 22px 32px 0 rgba(0, 0, 0, 0.1); }

.cart-title,
.block-title {
  font-size: 1.375rem;
  font-weight: bold; }

.cart-total-items {
  font-size: 1.375rem; }

.remove-all {
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: #3c3c3b underline;
  text-underline-position: under;
  -ms-text-underline-position: below; }

.cart-item {
  border-top: 1px solid rgba(60, 60, 59, 0.1); }
  .cart-item .product-name,
  .cart-item .product-price {
    font-size: 1.125rem;
    font-weight: bold; }
  .cart-item .product-name {
    line-height: 1.09; }
  .cart-item .product-price {
    line-height: 0.73; }
    .cart-item .product-price .price-label {
      font-size: 0.5rem;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 10px; }
  .cart-item .delete-item {
    width: 15px;
    height: 16px;
    object-fit: contain; }

.cart-sub-total-title {
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 20px; }

.cart-subtotal-price {
  text-align: right;
  font-weight: bold;
  line-height: 0.89;
  font-size: 1.125rem; }

.cart-bottom .block-title {
  margin-bottom: 11px; }
  .cart-bottom .block-title.delivery-title {
    font-size: 0.875rem;
    margin-bottom: 0; }
  .cart-bottom .block-title.grand-total-title {
    font-size: 1.5rem; }

.cart-bottom .promo-inputs {
  margin-bottom: 30px; }

.cart-bottom .applied-wrapper {
  background-color: rgba(216, 216, 216, 0.3);
  padding: 20px 15px 12px;
  margin-bottom: 33px;
  border-radius: 3px; }

.cart-bottom a {
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: #3c3c3b underline;
  text-underline-position: under; }

.cart-bottom .subtotal-wrapper {
  background-color: rgba(216, 216, 216, 0.3);
  padding: 20px 15px 12px;
  margin-bottom: 33px;
  border-radius: 3px; }
  .cart-bottom .subtotal-wrapper .delivery-price {
    font-weight: bold;
    text-align: right; }
  .cart-bottom .subtotal-wrapper .delivery-price {
    font-size: 0.875rem;
    line-height: 1.14; }

.cart-bottom .cart-bottom-right hr {
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 2rem; }

.cart-bottom .cart-bottom-right .grand-total-price {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right; }

.cart-bottom .cart-bottom-right button {
  width: 100%;
  height: 30px;
  line-height: 1.2; }

.qty-wrapper {
  position: relative;
  border-radius: 3px;
  height: 50px;
  align-self: center;
  background: #f3f3f3; }
  .qty-wrapper label {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: normal;
    margin-bottom: 0; }
  .qty-wrapper input {
    height: 100%;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 1.375rem;
    font-weight: bold;
    background: transparent;
    border: none; }
  .qty-wrapper input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
    margin-left: 10px; }

.cart-product-image {
  display: flex;
  min-height: 113px;
  align-items: center; }

.cart-remove-all {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center; }

@media (min-width: 768px) {
  .cart-bottom-left {
    margin-right: 4%; }
  .cart-item .product-name,
  .cart-item .product-price {
    font-size: 1.375rem; } }

@media (min-width: 1250px) {
  .cart-bottom-left {
    margin-right: 0; }
  .cart-bottom-left,
  .cart-bottom-right {
    flex: auto;
    max-width: initial; } }

.mini-cart-wrapper {
  position: absolute;
  right: 0;
  top: 70px;
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 12px 22px 0 rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  z-index: 99; }
  @media (min-width: 480px) {
    .mini-cart-wrapper {
      width: auto;
      right: 53px; } }
  @media (min-width: 768px) {
    .mini-cart-wrapper {
      width: 430px;
      top: 68px; } }
  @media (min-width: 1250px) {
    .mini-cart-wrapper {
      right: 0; } }

.mini-cart .cart-title,
.mini-cart .cart-total-items,
.mini-cart #item-qty,
.mini-cart .product-name,
.mini-cart .product-price {
  font-size: 1rem; }

.mini-cart .qty-wrapper {
  padding: 0; }
  .mini-cart .qty-wrapper > label {
    padding-left: 3px; }
  .mini-cart .qty-wrapper #item-qty {
    text-align: center;
    padding-left: 0; }

.mini-cart .cart-item {
  min-height: 80px; }

.mini-cart .cart-product-image {
  min-height: 100%; }

.mini-cart .cart-subtotal-price {
  font-size: 1.375rem;
  line-height: 2.375rem; }

.mini-cart-badge {
  position: absolute;
  top: 1px;
  right: 4px;
  font-size: 0.625rem;
  border-radius: 2px; }
  @media (min-width: 768px) {
    .mini-cart-badge {
      right: 3px;
      top: 16px; } }

.mini-cart-icon {
  z-index: 100;
  padding-bottom: 30px;
  margin-top: 20px;
  border-radius: 5px; }
  @media (min-width: 768px) {
    .mini-cart-icon {
      margin: auto;
      padding: initial; } }

/* Product Page */
.product-section {
  background: white; }

.share-buttons-wrapper {
  display: flex; }
  .share-buttons-wrapper > div {
    margin-right: 10px; }
  .share-buttons-wrapper > div:last-child {
    margin-right: 0; }

.product .qty-wrapper {
  min-width: 110px;
  max-width: 110px; }
  @media (min-width: 480px) {
    .product .qty-wrapper {
      min-width: 126px;
      max-width: 126px; } }
  .product .qty-wrapper #item-qty {
    text-align: right;
    font-size: 32px; }

.product-tabs-wrapper .nav {
  border-radius: 0;
  border-bottom: 1px solid rgba(60, 60, 50, 0.3); }
  .product-tabs-wrapper .nav .nav-link {
    line-height: 1;
    font-size: 0.875rem;
    margin-bottom: -1px;
    padding: 12px 17px 11px 17px;
    border: 1px solid transparent; }
    .product-tabs-wrapper .nav .nav-link.active {
      background-color: white;
      font-weight: bold;
      border-bottom: transparent; }
    @media (min-width: 768px) {
      .product-tabs-wrapper .nav .nav-link {
        padding: 18px 29px 15px 29px;
        font-size: 1rem; } }
  .product-tabs-wrapper .nav .nav-link:hover,
  .product-tabs-wrapper .nav .nav-link.active {
    color: #3c3c3b;
    border-radius: 0;
    border-color: rgba(60, 60, 50, 0.3); }
  .product-tabs-wrapper .nav .nav-link:hover {
    border-bottom-color: transparent; }

@media (min-width: 768px) {
  .product-details,
  .product-content-space {
    padding-left: 62px; } }

@media (min-width: 1024px) {
  .product-details {
    padding: 0 15px; }
  .product-content-space {
    padding-left: 108px; } }

/* Image Gallery (Carousel) */
.image-gallery {
  text-align: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }

.image-gallery-slide-wrapper {
  position: relative; }

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 113px); }

@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left,
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 84px); } }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap; }

.image-gallery-slide {
  background: white;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.image-gallery-slide.center {
  position: relative; }

.image-gallery-slide img {
  max-width: 100%;
  height: auto; }

.image-gallery-bullets {
  margin: 20px auto 0 auto;
  width: 80%; }

.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  z-index: 1; }

.image-gallery-bullets .image-gallery-bullet {
  position: relative;
  appearance: none;
  background-color: #d8d8d8;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  width: 18px;
  height: 18px; }

button.image-gallery-bullet.active:after {
  content: '';
  position: absolute;
  left: -4px;
  top: -4px;
  width: 24px;
  height: 24px;
  background: white;
  z-index: -1;
  border-radius: 50%;
  border: 1px solid #e7451c; }

@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px; } }

.image-gallery-thumbnails-wrapper {
  position: relative; }

.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl; }

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 108px; }

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0; }

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0; }

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0; }

.image-gallery-thumbnails-wrapper.left {
  margin-right: 5px; }

.image-gallery-thumbnails-wrapper.right {
  margin-left: 5px; }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  transition: transform .45s ease-out;
  white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border .3s ease-out;
  width: 100px; }

.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px; }

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  position: relative; }

.image-gallery-thumbnail img {
  width: 100%;
  border-radius: 5px;
  vertical-align: middle; }

.image-gallery-thumbnail.active {
  border: 2px solid #e7451c;
  border-radius: 5px; }

@media (max-width: 1023.98px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px; }
  .image-gallery-thumbnails-wrapper.left,
  .image-gallery-thumbnails-wrapper.right {
    width: 81px; }
  .image-gallery-thumbnails-wrapper.left {
    margin-right: 3px; }
  .image-gallery-thumbnails-wrapper.right {
    margin-left: 3px; }
  .image-gallery-thumbnails {
    padding: 3px 0; }
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 75px; } }

/* Account */
.account-holder .account-wrapper {
  margin: 0 0 149px; }
  .account-holder .account-wrapper .account-content {
    background: #f9f9f9; }
  .account-holder .account-wrapper > .account-dashboard {
    display: none; }

.account-holder.addresses-page .account-menu, .account-holder.details-page .account-menu, .account-holder.orders-page .account-menu, .account-holder.payments-page .account-menu, .account-holder.settings-page .account-menu {
  display: none; }

.account-holder > .account-dashboard,
.account-holder .account-wrapper > .account-dashboard {
  background-image: linear-gradient(150deg, #b4eaff, #3290ce); }
  .account-holder > .account-dashboard .dashboard-title,
  .account-holder .account-wrapper > .account-dashboard .dashboard-title {
    padding: 80px 0 140px; }

.account-menu {
  background: #ffffff; }
  .account-menu ul {
    width: 100%;
    padding: 0; }
    .account-menu ul li {
      list-style: none;
      padding-top: 1px; }
      .account-menu ul li.active {
        background-image: linear-gradient(271deg, #f9f9f9, #eff4f7);
        margin-top: -1px; }
        .account-menu ul li.active a {
          border-left: 5px solid #3290ce;
          border-bottom: 1px transparent;
          margin: 0;
          line-height: 72px; }
          .account-menu ul li.active a .link-icon {
            margin: 0 35px 0 20px; }
      .account-menu ul li a {
        font-size: 1.375rem;
        font-weight: bold;
        border-bottom: 1px solid rgba(60, 60, 50, 0.1);
        margin: 0 10px;
        line-height: 70px; }
        .account-menu ul li a .link-icon {
          width: 30px;
          display: inline-block;
          margin: 0 35px 0 15px;
          text-align: center; }
      .account-menu ul li:last-child a {
        border-bottom: 1px solid #ffffff; }
  .account-menu .account-welcome {
    margin: -48px auto 0;
    text-align: center; }
    .account-menu .account-welcome .avatar {
      margin: 0 auto;
      width: 107px;
      height: 107px;
      background-image: linear-gradient(337deg, #b4eaff, #3290ce);
      border-radius: 50%;
      font-size: 2.625rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      border: 6px solid #ffffff; }
    .account-menu .account-welcome .welcome-message {
      font-size: 1.375rem;
      color: #3c3c3b;
      line-height: 1;
      height: 97px;
      margin: 0 auto; }
      .account-menu .account-welcome .welcome-message span {
        justify-content: center;
        width: 100%; }

@media (min-width: 480px) {
  .account-menu .account-welcome .welcome-message {
    font-size: 1.375rem;
    color: #3c3c3b;
    line-height: 1;
    height: 97px; } }

@media (min-width: 768px) {
  .account-holder .account-wrapper {
    margin: 79px 0 149px; }
    .account-holder .account-wrapper > .account-dashboard {
      display: block; }
  .account-holder > .account-dashboard {
    display: none; }
  .account-holder.addresses-page .account-menu, .account-holder.details-page .account-menu, .account-holder.orders-page .account-menu, .account-holder.payments-page .account-menu, .account-holder.settings-page .account-menu {
    display: block; }
  .account-wrapper .account-content.account-dashboard .dashboard-title {
    margin-top: 158px; }
  .account-menu .account-welcome {
    margin: 50px 0 84px -30px;
    text-align: left; }
    .account-menu .account-welcome .avatar {
      width: 97px;
      height: 97px;
      border: none; }
    .account-menu .account-welcome .welcome-message {
      margin-left: 15px; }
      .account-menu .account-welcome .welcome-message span {
        margin-left: 15px;
        justify-content: normal;
        width: auto; }
  .account-menu ul li:first-of-type a {
    padding: 24px 0;
    line-height: 1rem; }
    .account-menu ul li:first-of-type a .link-icon {
      float: left; }
  .account-menu ul li:first-of-type.active a {
    line-height: 1.0625rem;
    padding: 24px 5px 24px 0; }
    .account-menu ul li:first-of-type.active a .link-icon {
      margin: 0 23px 0 14px; }
  .account-menu ul li.active a {
    padding: 1px 5px 0;
    line-height: 81px; }
    .account-menu ul li.active a .link-icon {
      margin: 0 23px 0 9px; }
  .account-menu ul li a {
    font-size: 1rem;
    line-height: 80px; }
    .account-menu ul li a .link-icon {
      margin: 0 23px 0 9px; } }

@media (min-width: 1024px) {
  .account-menu ul li:first-of-type.active a {
    padding: 0;
    line-height: 82px; }
    .account-menu ul li:first-of-type.active a .link-icon {
      float: none; }
  .account-menu ul li:first-of-type a {
    padding: 0;
    line-height: 80px; }
  .account-menu ul li a .link-icon {
    margin: 0 23px 0 9px; } }

@media (min-width: 1250px) {
  .account-menu ul li:first-of-type.active a {
    padding: 0; }
    .account-menu ul li:first-of-type.active a .link-icon {
      margin: 0 33px 0 14px; }
  .account-menu ul li:first-of-type a {
    padding: 0;
    line-height: 80px; }
  .account-menu ul li.active a .link-icon {
    margin: 0 33px 0 9px; }
  .account-menu ul li a .link-icon {
    margin: 0 33px 0 9px; } }

/* Whole School Program */
@media (max-width: 767.98px) {
  #resources h2 {
    text-align: center; } }

/* Outcomes */
.outcome-table {
  padding: 0; }
  .outcome-table .table-wrap .generic-table th.sticky-column.second:after {
    display: none; }
  .outcome-table .table-wrap .generic-table .section th.sticky-column:after {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .outcome-table .table-wrap .generic-table .section th.sticky-column.second {
    width: 100px; }

@media (min-width: 768px) {
  .outcome-table .table-wrap .generic-table {
    margin-left: 0;
    table-layout: unset; }
    .outcome-table .table-wrap .generic-table th.sticky-column {
      width: 500px; } }

@media (min-width: 1250px) {
  .outcome-table .table-wrap .generic-table {
    table-layout: fixed; } }

/* Success Stories Page */
.success-page .success-title {
  font-size: 1.875rem;
  line-height: 3.5; }

.success-page .success-paragraph {
  font-size: 1.125rem; }

.success-page .stories-wrapper {
  background: #f7f7f7;
  padding-bottom: 100px; }
  .success-page .stories-wrapper .recent-text {
    background: #ffffff; }
  .success-page .stories-wrapper .recent-title {
    font-size: 1.875rem;
    line-height: 2;
    padding-top: 40px;
    margin-left: -15px;
    padding-bottom: 20px; }
  .success-page .stories-wrapper .row {
    margin-bottom: 193px; }
  .success-page .stories-wrapper > .row:nth-of-type(2) {
    background: #ffffff; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .center-cropped {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      height: 320px;
      background-size: inherit; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story {
      margin: 0; }
  .success-page .stories-wrapper .story {
    margin: 0 15px; }
    .success-page .stories-wrapper .story .story-post {
      background: #ffffff;
      width: 100%; }
  .success-page .stories-wrapper .center-cropped {
    position: absolute;
    top: -173px;
    right: -15px;
    width: 241px;
    height: 231px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 300px;
    overflow: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background: #d8f5ff;
    background-image: -webkit-radial-gradient(3% 0, #d8f5ff, #dbe7ea);
    background-image: radial-gradient(circle at 3% 0, #d8f5ff, #dbe7ea);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px; }
    .success-page .stories-wrapper .center-cropped > img {
      height: 100%; }
  .success-page .stories-wrapper .post-date {
    font-size: 0.75rem;
    line-height: 1.83;
    padding-top: 40px;
    padding-bottom: 20px; }
  .success-page .stories-wrapper .post-title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
    margin-bottom: 1.25rem; }
  .success-page .stories-wrapper .read-more-text {
    font-size: 0.875rem;
    font-weight: bold;
    color: #3c3c3b;
    text-decoration: #3c3c3b underline;
    text-underline-position: under;
    -ms-text-underline-position: below;
    padding-bottom: 1.5625rem;
    margin-bottom: 0; }

@media (min-width: 480px) {
  .success-page .stories-wrapper .row {
    margin-bottom: 50px; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story {
    margin: 0 auto; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type {
      max-width: 100%;
      padding: 0; }
      .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type .center-cropped {
        margin-bottom: 30px; }
      .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type:before {
        content: ' ';
        width: 132px;
        height: 530px;
        display: inline;
        background: #00a14e;
        position: absolute;
        right: -15px;
        top: -40px; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6 {
      max-width: 66.66667%;
      padding: 0; }
  .success-page .stories-wrapper .story {
    margin: 0 auto; }
    .success-page .stories-wrapper .story .story-post {
      width: 100%; }
  .success-page .stories-wrapper .center-cropped {
    top: -25px;
    width: 281px;
    height: 319px;
    background-size: 390px; }
  .success-page .stories-wrapper .recent-title {
    margin-left: 0; } }

@media (min-width: 768px) {
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type {
    max-width: 100%;
    padding: 0; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type:before {
      right: -40px;
      top: -40px; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type .center-cropped {
      width: 100%;
      right: 0; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .story-post {
    width: 100%; }
    .success-page .stories-wrapper > .row:nth-of-type(2) .story .story-post .post-date {
      padding-bottom: 20px; }
  .success-page .stories-wrapper .story .story-post {
    width: 90%;
    min-height: 335px; }
    .success-page .stories-wrapper .story .story-post .center-cropped {
      width: 291px;
      right: -70px; } }

@media (min-width: 1024px) {
  .success-page .success-title {
    font-size: 3rem; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type:before {
    width: 260px;
    right: -50px; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type .center-cropped {
    background-size: 769px;
    width: 769px;
    margin-left: 35px; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6 {
    padding: 0 35px; }
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-7 {
    padding: 0 35px; }
  .success-page .stories-wrapper .story .story-post .col-lg-7 {
    padding: 0 35px; }
    .success-page .stories-wrapper .story .story-post .col-lg-7.post-date {
      padding: 35px 35px 15px; }
  .success-page .stories-wrapper .story .story-post .center-cropped {
    width: 392px;
    right: -91px; }
  .success-page .stories-wrapper .recent-title {
    padding-left: 35px;
    font-size: 2.5rem; } }

@media (min-width: 1250px) {
  .success-page .stories-wrapper > .row:nth-of-type(2) .story .col-sm-6:first-of-type:before {
    width: 294px;
    right: -92px; }
  .success-page .stories-wrapper .recent-title {
    margin-left: 8.33333%; } }

/* Planning */
.having-questions-description {
  font-size: 1.125rem !important; }
  @media (min-width: 768px) {
    .having-questions-description {
      font-size: 1.25rem !important;
      line-height: 1 !important; } }

.planning-questions-title {
  max-width: 623px;
  margin: 0 auto;
  font-size: 2rem !important;
  line-height: 1.13 !important; }
  @media (min-width: 768px) {
    .planning-questions-title {
      font-size: 2.625rem !important;
      line-height: 1 !important; } }

/* Covers it all */
@media (min-width: 768px) {
  .covers-uses-cta-title {
    max-width: 514px;
    margin: 0 auto; } }

.red-hexagon {
  position: relative;
  width: 38.6px;
  height: 22.2722px;
  background-color: #e7451c;
  margin: 11.1168px 0;
  border-left: 0;
  border-right: 0; }
  .red-hexagon:before, .red-hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: 19.3px solid transparent;
    border-right: 19.3px solid transparent; }
  .red-hexagon:before {
    bottom: 100%;
    border-bottom: 11.1168px solid #e7451c; }
  .red-hexagon:after {
    top: 100%;
    width: 0;
    border-top: 11.1168px solid #e7451c; }
  @media (min-width: 768px) {
    .red-hexagon {
      position: relative;
      width: 55px;
      height: 31.735px;
      background-color: #e7451c;
      margin: 15.84px 0;
      border-left: 0;
      border-right: 0; }
      .red-hexagon:before, .red-hexagon:after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        border-left: 27.5px solid transparent;
        border-right: 27.5px solid transparent; }
      .red-hexagon:before {
        bottom: 100%;
        border-bottom: 15.84px solid #e7451c; }
      .red-hexagon:after {
        top: 100%;
        width: 0;
        border-top: 15.84px solid #e7451c; } }

.blue-hexagon {
  position: relative;
  width: 38.6px;
  height: 22.2722px;
  background-color: #3290ce;
  margin: 11.1168px 0;
  border-left: 0;
  border-right: 0; }
  .blue-hexagon:before, .blue-hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: 19.3px solid transparent;
    border-right: 19.3px solid transparent; }
  .blue-hexagon:before {
    bottom: 100%;
    border-bottom: 11.1168px solid #3290ce; }
  .blue-hexagon:after {
    top: 100%;
    width: 0;
    border-top: 11.1168px solid #3290ce; }
  @media (min-width: 768px) {
    .blue-hexagon {
      position: relative;
      width: 55px;
      height: 31.735px;
      background-color: #3290ce;
      margin: 15.84px 0;
      border-left: 0;
      border-right: 0; }
      .blue-hexagon:before, .blue-hexagon:after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        border-left: 27.5px solid transparent;
        border-right: 27.5px solid transparent; }
      .blue-hexagon:before {
        bottom: 100%;
        border-bottom: 15.84px solid #3290ce; }
      .blue-hexagon:after {
        top: 100%;
        width: 0;
        border-top: 15.84px solid #3290ce; } }

.green-hexagon {
  position: relative;
  width: 38.6px;
  height: 22.2722px;
  background-color: #00a14e;
  margin: 11.1168px 0;
  border-left: 0;
  border-right: 0; }
  .green-hexagon:before, .green-hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: 19.3px solid transparent;
    border-right: 19.3px solid transparent; }
  .green-hexagon:before {
    bottom: 100%;
    border-bottom: 11.1168px solid #00a14e; }
  .green-hexagon:after {
    top: 100%;
    width: 0;
    border-top: 11.1168px solid #00a14e; }
  @media (min-width: 768px) {
    .green-hexagon {
      position: relative;
      width: 55px;
      height: 31.735px;
      background-color: #00a14e;
      margin: 15.84px 0;
      border-left: 0;
      border-right: 0; }
      .green-hexagon:before, .green-hexagon:after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        border-left: 27.5px solid transparent;
        border-right: 27.5px solid transparent; }
      .green-hexagon:before {
        bottom: 100%;
        border-bottom: 15.84px solid #00a14e; }
      .green-hexagon:after {
        top: 100%;
        width: 0;
        border-top: 15.84px solid #00a14e; } }

.yellow-hexagon {
  position: relative;
  width: 38.6px;
  height: 22.2722px;
  background-color: #f7b90c;
  margin: 11.1168px 0;
  border-left: 0;
  border-right: 0; }
  .yellow-hexagon:before, .yellow-hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: 19.3px solid transparent;
    border-right: 19.3px solid transparent; }
  .yellow-hexagon:before {
    bottom: 100%;
    border-bottom: 11.1168px solid #f7b90c; }
  .yellow-hexagon:after {
    top: 100%;
    width: 0;
    border-top: 11.1168px solid #f7b90c; }
  @media (min-width: 768px) {
    .yellow-hexagon {
      position: relative;
      width: 55px;
      height: 31.735px;
      background-color: #f7b90c;
      margin: 15.84px 0;
      border-left: 0;
      border-right: 0; }
      .yellow-hexagon:before, .yellow-hexagon:after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        border-left: 27.5px solid transparent;
        border-right: 27.5px solid transparent; }
      .yellow-hexagon:before {
        bottom: 100%;
        border-bottom: 15.84px solid #f7b90c; }
      .yellow-hexagon:after {
        top: 100%;
        width: 0;
        border-top: 15.84px solid #f7b90c; } }

/* Grant Writing Page */
#program-description h2 {
  margin-top: 60px; }

.white-toc-container {
  background: #ffffff; }

.grant-writing .gw-image {
  width: 100%;
  height: 250px; }

.grant-writing .program-description-image {
  background: url("/assets/svg/grant-writing/program-description.svg") no-repeat center center; }

.grant-writing .philosophy-image {
  background: url("/assets/svg/grant-writing/philosophy-image.svg") no-repeat center center; }

.grant-writing .school-program-image {
  background: url("/assets/svg/grant-writing/school-program-image.svg") no-repeat center center; }

.grant-writing .organization-tips-image {
  background: url("/assets/svg/grant-writing/organization-tips.svg") no-repeat center center; }

.grant-writing .program-description-image,
.grant-writing .school-program-image,
.grant-writing .organization-tips-image {
  background-size: 700px;
  margin-bottom: 60px; }

.grant-writing ul.ul-concepts, .grant-writing ul.ul-software, .grant-writing ul.ul-tip-grants, .grant-writing ul.ul-resources {
  font-size: 1.375rem; }

.grant-writing ul.ul-concepts {
  list-style: none;
  padding-left: 0; }
  .grant-writing ul.ul-concepts li {
    display: flex;
    margin-bottom: 10px;
    line-height: 1.4; }
    .grant-writing ul.ul-concepts li span {
      display: flex;
      margin-right: 20px;
      font-weight: bold; }
      .grant-writing ul.ul-concepts li span > span {
        display: inline;
        margin-left: 5px;
        margin-right: 0;
        color: #e7451c; }

.grant-writing ul.ul-software, .grant-writing ul.ul-resources {
  list-style: none;
  padding-left: 0;
  font-weight: 600;
  line-height: 2.3; }

.grant-writing ul.ul-software {
  margin-bottom: 40px; }

.grant-writing h4 {
  font-size: 1.375rem; }

@media (min-width: 480px) {
  .grant-writing .gw-image {
    width: 100%;
    height: 330px; } }

@media (min-width: 768px) {
  .grant-writing .gw-image {
    width: 100%;
    height: 300px;
    max-width: 490px;
    max-height: 300px; } }

@media (min-width: 1024px) {
  .grant-writing .gw-image {
    width: 100%;
    height: 330px;
    max-width: 635px;
    max-height: 330px; } }

@media (min-width: 1250px) {
  .white-toc-container .toc-fixed-menu ul {
    margin-top: 70px; }
  .grant-writing .gw-image {
    width: 100%;
    height: 375px;
    max-width: 690px;
    max-height: 375px; } }

/* Resources Page */
.monitoring-surveys-wrapper,
.sample-lessons-wrapper,
.program-resources-wrapper {
  background: #ffffff; }

.sample-lesson,
.program-resources {
  padding: 45px 0; }
  .sample-lesson#music-resources,
  .program-resources#music-resources {
    padding: 45px 0 0; }

.music-res-link {
  padding-bottom: 45px; }

.survey-wrapper,
.sl-wrapper,
.resource-wrapper {
  display: flex; }
  .survey-wrapper .pdf-icon,
  .sl-wrapper .pdf-icon,
  .resource-wrapper .pdf-icon {
    display: inline-block;
    width: 29px;
    height: 34px;
    background: url("/assets/svg/icon-pdf.svg") no-repeat center center;
    margin: 15px 23px 15px 0; }
  .survey-wrapper a,
  .sl-wrapper a,
  .resource-wrapper a {
    align-self: center;
    font-size: 1rem; }

.sl-wrapper .pdf-icon {
  min-width: 29px; }

/* Music player */
.react-jinke-music-player-main.dark-theme .progress-bar {
  background-color: inherit; }

/* SEL Page  */
.skills-title {
  max-width: 450px;
  line-height: 3.75rem !important; }

@media (min-width: 1024px) {
  .skills-title {
    max-width: 685px; } }

/* Budget Friendly */
.budget-friendly .budget-more-content {
  border-radius: 10px;
  background-color: #edf8fc;
  padding: 60px 0;
  text-align: center;
  margin: 80px 0 50px; }
  .budget-friendly .budget-more-content .mc-text {
    font-size: 4rem;
    font-weight: bold; }
  .budget-friendly .budget-more-content span {
    display: block;
    width: 144px;
    height: 1px;
    margin: 37px auto 46px;
    background-color: #3c3c3b; }
  .budget-friendly .budget-more-content .mc-subtext {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 10px;
    text-transform: uppercase; }

.budget-friendly .para-title {
  font-size: 2rem;
  font-weight: bold; }

.budget-friendly .blue-wrapper {
  text-align: center;
  background-color: #edf8fc;
  border-radius: 5px;
  padding: 15px 0 13px; }
  .budget-friendly .blue-wrapper .b-math-formula {
    margin: 10px 20px 0 20px;
    border-top: 1px solid rgba(60, 60, 50, 0.3);
    padding-top: 18px; }

.budget-friendly .bb-wrapper {
  background-image: radial-gradient(circle at 31% 2%, #f7fdff, #e6f6fa 123%);
  border-radius: 5px;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 36px; }
  .budget-friendly .bb-wrapper .w-border {
    border-right: 1px solid rgba(60, 60, 50, 0.3); }
  .budget-friendly .bb-wrapper .roi-title,
  .budget-friendly .bb-wrapper .b-outcomes-title,
  .budget-friendly .bb-wrapper .cbr-title {
    margin-top: 30px; }
  .budget-friendly .bb-wrapper .cbr-value,
  .budget-friendly .bb-wrapper .roi-value,
  .budget-friendly .bb-wrapper .b-outcomes-value {
    margin-top: 20px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #e7451c; }

.budget-friendly a.btn-primary {
  margin: 30px 0;
  padding: 0 100px;
  font-weight: 600;
  line-height: 1.8; }

.budget-friendly h3 {
  font-size: 2rem;
  font-weight: bold; }

.budget-friendly .fbe-wrapper {
  border-radius: 10px;
  background-color: rgba(0, 161, 78, 0.1);
  margin-bottom: 30px;
  text-align: center; }

.budget-friendly .fbe-icon {
  padding: 25px 0; }

.budget-friendly .fbe-text {
  max-width: 310px;
  padding: 0 20px; }

.budget-friendly .fbe-graph {
  margin-top: 10px;
  margin-bottom: 20px; }
  .budget-friendly .fbe-graph span {
    font-size: 2rem;
    font-weight: bold;
    padding: 0 0 0 20px;
    color: #00a14e; }

.budget-friendly .outcome-table-wrapper {
  background-image: radial-gradient(circle at 31% 2%, #f7fdff, #e6f6fa 113%);
  border-radius: 5px; }
  .budget-friendly .outcome-table-wrapper .row {
    padding: 37px 0;
    border-bottom: 1px solid rgba(60, 60, 50, 0.1); }
    .budget-friendly .outcome-table-wrapper .row .col {
      font-size: 1.125rem; }
    .budget-friendly .outcome-table-wrapper .row:nth-of-type(1) {
      padding-bottom: 0; }
    .budget-friendly .outcome-table-wrapper .row:nth-of-type(1), .budget-friendly .outcome-table-wrapper .row:last-of-type {
      border-bottom: none; }
  .budget-friendly .outcome-table-wrapper .font-size-22 {
    font-size: 1.375rem; }
  .budget-friendly .outcome-table-wrapper a.btn-primary {
    margin: 0;
    padding: 0 100px;
    font-weight: 600;
    line-height: 1.8;
    padding: 0 22px; }

.budget-friendly .c-ref-link {
  font-size: 1rem; }

.budget-friendly sup {
  font-size: 0.75rem;
  font-weight: bold;
  color: #e7451c;
  margin-right: 2px; }

@media (min-width: 768px) {
  .budget-friendly .outcome-table-wrapper .row .col {
    font-size: 1.375rem; }
  .budget-friendly .outcome-table-wrapper a.btn-primary {
    padding: 0 9px; }
  .budget-friendly .fbe-icon {
    padding: 25px 0 25px 50px; } }

@media (min-width: 1024px) {
  .budget-friendly .fbe-graph span {
    padding: 0 5px 0 11px; } }

@media (min-width: 1250px) {
  .budget-friendly .fbe-graph span {
    padding: 0 60px 0 11px; }
  a.btn-primary {
    padding: 0 22px; } }
